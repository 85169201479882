%arcadeH1 {
  @apply font-semibold leading-tiny;
  font-size: 4.6875rem;
  @screen sm {
    @apply font-bold;
    font-size: 2.625rem;
  }
  @screen md {
    @apply font-bold;
    font-size: 2.625rem;
  }
}
%arcadeH2 {
  @apply font-semibold leading-tiny;
  font-size: 3.75rem;
}
%arcadeH3 {
  @apply font-semibold leading-tight;
  font-size: 3.125rem;
}
%arcadeH4 {
  @apply font-semibold leading-tiny;
  font-size: 2.625rem;
  @screen sm {
    font-size: 1.875rem;
  }
  @screen md {
    font-size: 1.875rem;
  }
}
%arcadeH5 {
  @apply font-semibold;
  font-size: 1.875rem;
  line-height: 1.2rem;
  @screen sm {
    font-size: 1.5rem;
    line-height: 1.15rem;
  }
  @screen md {
    font-size: 1.5rem;
    line-height: 1.15rem;
  }
}
%arcadeH6 {
  @apply font-semibold;
  font-size: 1.6875rem;
  line-height: 1.3rem;
  @screen sm {
    font-size: 1.375rem;
    line-height: 1.15rem;
  }
  @screen md {
    font-size: 1.375rem;
    line-height: 1.15rem;
  }
}
%arcadeH7 {
  @apply font-semibold;
  font-size: 1.5rem;
  line-height: 1.3rem;
  @screen sm {
    font-size: 1.375rem;
    line-height: 1.15rem;
  }
  @screen md {
    font-size: 1.375rem;
    line-height: 1.15rem;
  }
}
%arcadeP1 {
  @apply font-normal;
  font-size: 1.5rem;
  line-height: 1.2rem;
}
%arcadeP2 {
  @apply font-normal;
  font-size: 1.3125rem;
  line-height: 1.3rem;
  @screen sm {
    font-size: 1.125rem;
    line-height: 1.33rem;
  }
  @screen md {
    font-size: 1.125rem;
    line-height: 1.33rem;
  }
}
%arcadeP3 {
  @apply font-normal;
  font-size: 1.125rem;
  line-height: 1.3rem;
}
%arcadeCTA1 {
  @apply font-semibold;
  font-size: 1.3125rem;
  line-height: 1rem;
}
%arcadeCTA2 {
  @apply font-semibold;
  font-size: 1rem;
  line-height: 1rem;
  @screen sm {
    @apply font-bold;
  }
  @screen md {
    @apply font-bold;
  }
}
%arcadeFooter1 {
  @apply font-semibold;
  font-size: 1.3125rem;
  line-height: 1.2rem;
  @screen sm {
    @apply font-bold;
    font-size: 1.25rem;
    line-height: 1rem;
  }
  @screen md {
    @apply font-bold;
    font-size: 1.25rem;
    line-height: 1rem;
  }
}
%arcadeFooter2 {
  @apply font-normal;
  font-size: 0.875rem;
  line-height: 1.3rem;
  @screen sm {
    line-height: 1.55rem;
  }
  @screen md {
    line-height: 1.55rem;
  }
}
%arcadeNav {
  @apply font-normal;
  font-size: 1rem;
  line-height: 1.3rem;
  @screen sm {
    line-height: 1rem;
  }
  @screen md {
    line-height: 1rem;
  }
}
%arcadeSmall {
  @apply font-normal;
  font-size: 0.75rem;
  line-height: 1.3rem;
  @screen sm {
    line-height: 1.2rem;
  }
  @screen md {
    line-height: 1.2rem;
  }
}

// @layer utilities {
//   .arcade-h1 {
//     @apply font-semibold leading-tiny;
//     font-size: 4.6875rem;
//     @screen sm {
//       @apply font-bold;
//       font-size: 2.625rem;
//     }
//     @screen md {
//       @apply font-bold;
//       font-size: 2.625rem;
//     }
//   }
//   .arcade-h2 {
//     @apply font-semibold leading-tiny;
//     font-size: 3.75rem;
//   }
//   .arcade-h3 {
//     @apply font-semibold leading-tight;
//     font-size: 3.125rem;
//   }
//   .arcade-h4 {
//     @apply font-semibold leading-tiny;
//     font-size: 2.625rem;
//     @screen sm {
//       font-size: 1.875rem;
//     }
//     @screen md {
//       font-size: 1.875rem;
//     }
//   }
//   .arcade-h5 {
//     @apply font-semibold;
//     font-size: 1.875rem;
//     line-height: 1.2rem;
//     @screen sm {
//       font-size: 1.5rem;
//       line-height: 1.15rem;
//     }
//     @screen md {
//       font-size: 1.5rem;
//       line-height: 1.15rem;
//     }
//   }
//   .arcade-h6 {
//     @apply font-semibold;
//     font-size: 1.6875rem;
//     line-height: 1.3rem;
//     @screen sm {
//       font-size: 1.375rem;
//       line-height: 1.15rem;
//     }
//     @screen md {
//       font-size: 1.375rem;
//       line-height: 1.15rem;
//     }
//   }
//   .arcade-h7 {
//     @apply font-semibold;
//     font-size: 1.5rem;
//     line-height: 1.3rem;
//     @screen sm {
//       font-size: 1.375rem;
//       line-height: 1.15rem;
//     }
//     @screen md {
//       font-size: 1.375rem;
//       line-height: 1.15rem;
//     }
//   }
//   .arcade-p1 {
//     @apply font-normal;
//     font-size: 1.5rem;
//     line-height: 1.2rem;
//   }
//   .arcade-p2 {
//     @apply font-normal;
//     font-size: 1.3125rem;
//     line-height: 1.3rem;
//     @screen sm {
//       font-size: 1.125rem;
//       line-height: 1.33rem;
//     }
//     @screen md {
//       font-size: 1.125rem;
//       line-height: 1.33rem;
//     }
//   }
//   .arcade-p3 {
//     @apply font-normal;
//     font-size: 1.125rem;
//     line-height: 1.3rem;
//   }
//   .arcade-cta-1 {
//     @apply font-semibold;
//     font-size: 1.3125rem;
//     line-height: 1rem;
//   }
//   .arcade-cta-2 {
//     @apply font-semibold;
//     font-size: 1rem;
//     line-height: 1rem;
//     @screen sm {
//       @apply font-bold;
//     }
//     @screen md {
//       @apply font-bold;
//     }
//   }
//   .arcade-footer-1 {
//     @apply font-semibold;
//     font-size: 1.3125rem;
//     line-height: 1.2rem;
//     @screen sm {
//       @apply font-bold;
//       font-size: 1.25rem;
//       line-height: 1rem;
//     }
//     @screen md {
//       @apply font-bold;
//       font-size: 1.25rem;
//       line-height: 1rem;
//     }
//   }
//   .arcade-footer-2 {
//     @apply font-normal;
//     font-size: 0.875rem;
//     line-height: 1.3rem;
//     @screen sm {
//       line-height: 1.55rem;
//     }
//     @screen md {
//       line-height: 1.55rem;
//     }
//   }
//   .arcade-nav {
//     @apply font-normal;
//     font-size: 1rem;
//     line-height: 1.3rem;
//     @screen sm {
//       line-height: 1rem;
//     }
//     @screen md {
//       line-height: 1rem;
//     }
//   }
//   .arcade-small {
//     @apply font-normal;
//     font-size: 0.75rem;
//     line-height: 1.3rem;
//     @screen sm {
//       line-height: 1.2rem;
//     }
//     @screen md {
//       line-height: 1.2rem;
//     }
//   }
// }

