@import "styles/variables";
@import "styles/colors";
@import "styles/font";
@import "styles/arcade";

@tailwind base;
// The preflight css is setting "currentColor" (with ") which breaks this rule and the site,
// I imagine a permanent fix will require work with the tailwind team, this is a workaround in the meantime.
*,
:after,
:before {
    border: 0 solid currentColor;
}
@tailwind components;
@tailwind utilities;

body {
    // max-width: 1440px;
    // margin: 0 auto;
    // overflow-x: hidden;
    // overscroll-behavior: none;
    //setting new-user-modal backdrop to highest z-index
    div[data-testid="backdrop"] {
        z-index: 99999;
    }
}

.builder-text {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span,
    a,
    li {
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
}

@layer utilities {
    .heading8XL,
    .heading7XL,
    .heading6XL,
    .heading5XL,
    .heading4XL,
    .heading3XL,
    .heading2XL,
    .headingXL,
    .headingL,
    .headingM,
    .headingS,
    .headingXS,
    .headingXXS,
    .content3XL,
    .content2XL,
    .contentXL,
    .contentL,
    .contentM,
    .contentS,
    .contentXS,
    .contentXXS {
        @apply font-italianplate not-italic;
    }

    .heading8XL,
    .heading7XL,
    .heading6XL,
    .heading5XL,
    .heading4XL,
    .heading3XL,
    .heading2XL,
    .headingXL,
    .headingL,
    .headingM,
    .headingS,
    .headingXS,
    .headingXXS {
        @apply font-bold;
    }

    .content3XL,
    .content2XL,
    .contentXL,
    .contentL,
    .contentM,
    .contentS,
    .contentXS,
    .contentXXS {
        @apply font-normal;
    }

    .heading8XL {
        @apply text-8xl leading-22;
    }

    .heading7XL {
        @apply text-7xl leading-20;
    }

    .heading6XL {
        @apply text-6xl leading-18;
    }

    .heading5XL {
        @apply text-5xl leading-16;
    }

    .heading4XL {
        @apply text-4xl leading-16;
    }

    .heading3XL,
    .content3XL {
        @apply text-3xl leading-13;
    }

    .heading2XL,
    .content2XL {
        @apply text-2xl leading-10;
    }

    .headingXL,
    .contentXL {
        @apply text-xl leading-8;
    }

    .headingL,
    .contentL {
        @apply text-lg leading-7;
    }

    .headingM,
    .contentM {
        @apply text-md leading-6;
    }

    .contentS,
    .headingS {
        @apply text-sm leading-5-5;
    }

    .headingXS,
    .contentXS {
        @apply text-xs leading-5;
    }

    .headingXXS,
    .contentXXS {
        @apply text-xxs leading-4;
    }

    .arcade-h1 {
        @extend %arcadeH1;
    }
    .arcade-h2 {
        @extend %arcadeH2;
    }
    .arcade-h3 {
        @extend %arcadeH3;
    }
    .arcade-h4 {
        @extend %arcadeH4;
    }
    .arcade-h5 {
        @extend %arcadeH5;
    }
    .arcade-h6 {
        @extend %arcadeH6;
    }
    .arcade-h7 {
        @extend %arcadeH7;
    }
}

.arcade {
    h1 {
      @apply arcade-h1;
    }
    h2 {
      @apply arcade-h2;
    }
    h3 {
      @apply arcade-h3;
    }
    h4 {
      @apply arcade-h4;
    }
    h5 {
      @apply arcade-h5;
    }
    h6 {
      @apply arcade-h6;
    }
    h7 {
      @apply arcade-h7;
    }
  }