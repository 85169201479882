@font-face {
    font-family: "Italian Plate No2 Expanded";
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Regular.eot");
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Regular.woff2") format("woff2"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Italian Plate No2 Expanded";
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Demibold.eot");
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Demibold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Demibold.woff2") format("woff2"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Demibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Italian Plate No2 Expanded";
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Bold.eot");
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Bold.woff2") format("woff2"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Italian Plate No2 Expanded";
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Italic.eot");
    src: url("../assets/fonts/ItalianPlateNo2Expanded-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Italic.woff2") format("woff2"),
        url("../assets/fonts/ItalianPlateNo2Expanded-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}


/* Cash Sans */
@font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-ExtlightItl.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-MediumItl.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-SemiboldItl.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Extlight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-RegularItl.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-LightItl.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Extblack.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-BlackItl.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-ExtblackItl.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cash Sans';
    src: url('../assets/fonts/CashSans/CashSans-BoldItl.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
